export const calculatorButtons = [
  {
    type: "clear",
    className: "ac",
    text: "AC",
    value: "All Clear",
  },
  {
    type: "number",
    className: "seven",
    text: 7,
    value: 7,
  },
  {
    type: "number",
    className: "eight",
    text: 8,
    value: 8,
  },
  {
    type: "number",
    className: "nine",
    text: 9,
    value: 9,
  },
  {
    type: "number",
    className: "four",
    text: 4,
    value: 4,
  },
  {
    type: "number",
    className: "five",
    text: 5,
    value: 5,
  },
  {
    type: "number",
    className: "six",
    text: 6,
    value: 6,
  },
  {
    type: "number",
    className: "one",
    text: 1,
    value: 1,
  },
  {
    type: "number",
    className: "two",
    text: 2,
    value: 2,
  },
  {
    type: "number",
    className: "three",
    text: 3,
    value: 3,
  },
  {
    type: "number",
    className: "zero",
    text: 0,
    value: 0,
  },
  {
    type: "decimal",
    className: "decimal",
    text: ".",
    value: ".",
  },
  {
    type: "sign",
    className: "inverse",
    text: "+/-",
    value: "(-",
  },
  {
    type: "operator",
    className: "percent",
    text: "%",
    value: "/100",
  },
  {
    type: "operator",
    className: "square-root",
    text: "\u221a",
    value: "sqrt(",
  },
  {
    type: "operator",
    className: "multiply",
    text: "\u00d7",
    value: "*",
  },
  {
    type: "operator",
    className: "divide",
    text: "\u00f7",
    value: "/",
  },
  {
    type: "operator",
    className: "add",
    text: "+",
    value: "+",
  },
  {
    type: "operator",
    className: "subtract",
    text: "-",
    value: "-",
  },
  {
    type: "bracket",
    className: "left-bracket",
    text: "(",
    value: "(",
  },
  {
    type: "bracket",
    className: "right-bracket",
    text: ")",
    value: ")",
  },
  {
    type: "exponent",
    className: "square",
    text: "X\u00b2",
    value: "square(",
  },
  {
    type: "exponent",
    className: "cubed",
    text: "X\u00B3",
    value: "cube(",
  },
  {
    type: "enter",
    className: "equal",
    text: "=",
    value: "Equal",
  },
];
